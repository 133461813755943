import skeleton from './skeleton.vue';
import skeletonCircle from './basic/skeleton-circle.vue';
import skeletonSquare from './basic/skeleton-square.vue';
import skeletonRow from './layout/skeleton-row.vue';
import skeletonColumn from './layout/skeleton-column.vue';

skeleton.skeletonCircle = skeletonCircle;
skeleton.skeletonSquare = skeletonSquare;
skeleton.skeletonRow = skeletonRow;
skeleton.skeletonColumn = skeletonColumn;

export default skeleton;
