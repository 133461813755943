import { render, staticRenderFns } from "./magic.vue?vue&type=template&id=601c4726&scoped=true&"
import script from "./magic.vue?vue&type=script&lang=js&"
export * from "./magic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "601c4726",
  null
  
)

export default component.exports