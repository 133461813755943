var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nut-countup"},[(_vm.customBgImg != '')?[(_vm.type == 'machine')?[_c('ul',{staticClass:"run-number-machine-img",style:({ height: _vm.numHeight + 'px' })},_vm._l((_vm.machineNum),function(val,index){return _c('li',{key:'mImg' + index,ref:"run-number-machine-img-li",refInFor:true,staticClass:"run-number-machine-img-li",style:({
            width: _vm.numWidth + 'px',
            height: _vm.numHeight + 'px',
            backgroundImage: 'url(' + _vm.customBgImg + ')',
            backgroundPositionY: _vm.prizeY[index] + 'px'
          })})}),0)]:[_c('ul',{staticClass:"run-number-img",style:({ height: _vm.numHeight + 'px' })},[_vm._l((_vm.num_total_len),function(val,index){return _c('li',{key:'cImg' + index,staticClass:"run-number-img-li",style:({
            width: _vm.numWidth + 'px',
            height: _vm.numHeight + 'px',
            left:
              _vm.numWidth * (index > _vm.num_total_len - _vm.pointNum - 1 ? (index == _vm.num_total_len - _vm.pointNum ? index * 1.5 : index * 1.3) : index) + 'px',
            backgroundImage: 'url(' + _vm.customBgImg + ')',
            backgroundPosition: '0 ' + -(String(_vm.relNum)[index] * _vm.numHeight + _vm.customSpacNum * String(_vm.relNum)[index]) + 'px',
            transition: 'all linear ' + _vm.during / 10 + 'ms'
          })})}),_vm._v(" "),(_vm.pointNum > 0)?_c('div',{staticClass:"pointstyl",style:({
            width: _vm.numWidth / 2 + 'px',
            bottom: 0,
            left: _vm.numWidth * (_vm.num_total_len - _vm.pointNum) * 1.1 + 'px',
            fontSize: '30px'
          })},[_vm._v(".")]):_vm._e()],2)]]:[(_vm.scrolling)?_c('ul',{staticClass:"run-number",style:({ width: _vm.numWidth * _vm.num_total_len + _vm.numWidth / 3 + 'px', height: _vm.numHeight + 'px', lineHeight: _vm.numHeight + 'px' })},[_vm._l((_vm.num_total_len),function(val,index){return _c('li',{key:val,ref:"numberItem",refInFor:true,style:({
          top: _vm.topNumber(index),
          left: _vm.numWidth * (index > _vm.num_total_len - _vm.pointNum - 1 ? index * 1.1 : index) + 'px'
        }),attrs:{"turn-number":_vm.turnNumber(index)}},_vm._l((_vm.to0_10),function(item,idx){return _c('span',{key:'dote' + idx,style:({ width: _vm.numWidth + 'px', height: _vm.numHeight + 'px', lineHeight: _vm.numHeight + 'px' })},[_vm._v("\n          "+_vm._s(item)+"\n        ")])}),0)}),_vm._v(" "),(_vm.pointNum > 0)?_c('div',{staticClass:"pointstyl",style:({
          width: _vm.numWidth / 3 + 'px',
          height: _vm.numHeight + 'px',
          lineHeight: _vm.numHeight + 'px',
          top: 0,
          left: _vm.numWidth * (_vm.num_total_len - _vm.pointNum) + 'px'
        })},[_vm._v(".")]):_vm._e()],2):[_vm._v("\n      "+_vm._s(_vm.current)+"\n    ")]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }